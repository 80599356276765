/* eslint-disable */
/* eslint-env browser */
import React from 'react';
import './skins/shaka/videojs.min.css';
import videojs from 'video.js';

import './components/nuevo';

export default class VideoPlayer extends React.Component {
  componentDidMount() {
    this.player = videojs(this.videoNode, this.props, () => {
      console.log('Player ready!');
    });
    const nuevoOptions = {
      // logo: '//cdn.nuevodevel.com/img/logo_small.png',
      // logourl: '//www.nuevodevel.com/nuevo/',
      downloadButton:true,
      // downloadURL: 'https://res.cloudinary.com/teachzy-images/video/upload/v1627377111/hirezen/videoplayback_swgzol.mp4',
      videoInfo: true,
      // infoUrl: 'https://www.nuevodevel.com/nuevo/order',
      infoTitle: 'Random Title that we can add',
      infoDescription: 'Random Description that we can add',
      url: 'https://www.nuevodevel.com/nuevo/order',
      chapterMarkers: true
    };
    this.player.nuevo(nuevoOptions);

    const tracks = [
      {
        kind: 'chapters',
        src: './steal_chapters.vtt',
        srclang: 'en',
        label: 'Chapters',
      },
    ];
    console.log(tracks)
    this.player.on('nuevoReady', function () {
      console.log("loaded?")
      this.loadTracks(tracks);
      // this.
    });
  }

  // eslint-disable-next-line react/sort-comp
  onClickT = () => {
    this.player.currentTime(2);
  };

  onClick2 = () => {
    this.player.currentTime(3);
  };

  goPip = () => {
    this.player.pip();
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }
  //
  // this.player.on('downloadVideo', function(event,data){
  //   video_url = data.source;
  //   video_id = data.id;
  //   //send file to server-side file with download function
  // });


  render() {
    return (
      <div className="container">
        <div data-vjs-player>
          <video
            ref={(node) => (this.videoNode = node)}
            className="video-js vjs-fluid"
          />
        </div>
        <button onClick={this.onClickT}> Press, go to 200ms </button>
        <button onClick={this.onClick2}> Press, go to 300ms </button>
        {/*<button onClick={this.goPip}> go pip </button>*/}

      </div>
    );
  }
}
