import React, { useEffect, useState } from 'react';
import { Fab, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { RecordingTabs } from './components/RecordingsTabs';
import { TagsFilterOptions } from '../../constants';
import videosMock from '../../mockData/videosMock.json';
import { SearchBar } from './components/SearchBar';
import { VideoCard } from './components/VideoCard';
import { VideoPlayerModal } from '../../components/VideoPlayerModal';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 1000,
  },
  rightContainer: {
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(6),
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    padding: 24,
    borderRadius: 24,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(8),
    backgroundColor: '#046eef',
    '&:hover': {
      backgroundColor: '#046eef',
    },
  },
}));

const RecordingScreen: React.FC = () => {
  const classes = useStyles();
  const [filters, setFilters] = useState({
    tag: TagsFilterOptions.all,
  });
  const [videos, setVideos] = useState<any>(videosMock);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState<any>(null);

  useEffect(() => {
    const filteredVideos = videosMock.filter((video: any) => video.tags.indexOf(filters.tag) > -1 || filters.tag === TagsFilterOptions.all);
    setVideos(filteredVideos);
  }, [filters]);

  const openContent = (video: any):void => {
    setVideoModalOpen(true);
    setSelectedContent(video);
  };

  return (

    <div style={{ backgroundColor: '#f8f9fc' }}>
      <RecordingTabs />
      {/* <SearchBar {...{ filters, setFilters }} /> */}
      <div className={classes.cardsContainer}>
        {videos.map((video: any) => (
          <VideoCard
            video={video}
            setSelectedContent={() => {
              openContent(video);
            }}
          />
        ))}
      </div>

      {videoModalOpen && selectedContent && selectedContent.videoUrl && (
        <VideoPlayerModal
          videoUrl={selectedContent.videoUrl}
          handleClose={() => setVideoModalOpen(false)}
          open
        />
      )}

      <Fab aria-label="new" className={classes.fab}>
        <AddIcon style={{ color: 'white' }} />
      </Fab>
    </div>
  );
};

export default RecordingScreen;
