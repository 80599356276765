import { apiFailureErrorMessage, HttpService } from '../utils/httpService';
import { ENDPOINTS } from '../config/endpoints/stage';

export class DeviceService {
  constructor(private httpService: HttpService) {
    this.httpService = httpService;
  }

  async fetchDeviceList() {
    const url = `${ENDPOINTS.ROOT_URL}/accounts/1/devices`;
    try {
      const { data } = await this.httpService.get<any>(url);
      return data;
    } catch (error) {
      throw new Error(apiFailureErrorMessage);
    }
  }
}
