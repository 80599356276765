/* eslint-disable */
/* eslint-env browser */
import { ENDPOINTS } from '../config';
import { apiFailureErrorMessage, HttpService } from '../utils';

export class UserService {
  constructor(private httpService: HttpService) {
    this.httpService = httpService;
  }

  async fetchUsers(accountId: string) {
    const url = `${ENDPOINTS.ROOT_URL}/accounts/${accountId}/users`;
    try {
      const { data } = await this.httpService.get<any>(url);
      return data;
    } catch (error) {
      throw new Error(apiFailureErrorMessage);
    }
  }

  async validateInviteCode(inviteCode: string, email: string) {
    const url = `${ENDPOINTS.ROOT_URL}/invite/${inviteCode}?email=${email}`;
    try {
      const { data } = await this.httpService.get<any>(url);
      return data;
    } catch (error) {
      throw new Error(apiFailureErrorMessage);
    }
  }

  async inviteUser(userEmail: string, selfEmail: string) {
    const url = `${ENDPOINTS.ROOT_URL}/invite/1`;
    try {
      const { data } = await this.httpService.post<any>(url, {
        data: {
          to_email: userEmail,
          from_email: selfEmail
        }
      });
      return data;
    } catch (error) {
      throw new Error(apiFailureErrorMessage);
    }
  }
}
