import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { TokenService } from '../token';

// export interface HandleStatusCode {
// eslint-disable-next-line no-tabs
// 	[key: number]: (error: AxiosError) => void;
// }

export const apiFailureErrorMessage = 'Failure in API call';

export class HttpService {
  // eslint-disable-next-line no-useless-constructor
  constructor(protected tokenService: TokenService) {
  }

  async get<T>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.request<T>({ method: 'GET', url, ...config });
  }

  async post<T>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.request<T>({ method: 'POST', url, ...config });
  }

  async put<T>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.request<T>({ method: 'PUT', url, ...config });
  }

  async patch<T>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.request<T>({ method: 'PATCH', url, ...config });
  }

  async delete<T>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.request<T>({ method: 'DELETE', url, ...config });
  }

  // eslint-disable-next-line class-methods-use-this
  async request<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const defaultRequestInfo: AxiosRequestConfig = {
      headers: {
        // 'Content-Type': 'application/json;charset=UTF-8',
      },
    };

    return axios.request<T>({
      ...defaultRequestInfo,
      ...config,
      headers: {
        ...defaultRequestInfo.headers,
        ...config.headers,
      },
    });
  }
  // onHttpErrorStatus(
  //   error: AxiosError,
  //   handleStatusCode: HandleStatusCode,
  // ): void {
  //   if (error.response && error.response.status) {
  //     const statusCode = error.response.status;
  //     const handleStatusCodeFn = handleStatusCode[statusCode];
  //
  //     if (typeof handleStatusCodeFn === 'function') {
  //       handleStatusCodeFn(error);
  //     }
  //   }
  // }
}
