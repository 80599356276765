// Pion Initializations
/* eslint-disable */
// Pion Initializations
/* eslint-env browser */

import React, {useState} from 'react';
import {
    Avatar, Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    withStyles,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import {useAsync} from 'react-use';
import UserImage from '../../download.png';
import {useServices} from '../../services';
import LoaderScreen from '../LoaderScreen/LoaderScreen';
import AddIcon from "@material-ui/icons/Add";
import AddUserPopup from './components/AddUserPopup';

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    userInfoContainer: {
        display: 'flex',
        justifyContent: 'row',
        alignItems: 'center',
    },
    userImage: {
        height: 28,
        width: 28,
        marginRight: 8,
        borderRadius: 14
    },
    tableHead: {
        backgroundColor: '#f8f9fc',
        borderRadius: 10,
        border: 'none',
        opacity: 0.5
    },
    leftBorderRadius: {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10
    },
    rightBorderRadius: {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10
    },
    tableRow: {
        borderSpacing: '0px 20px',
        boxShadow: '4px 4px 5px 0 rgba(229, 229, 229, 0.63)',
        borderRadius: 10,
    },
    userNameText: {
        fontSize: 16,
        color: '#15192c',
        lineHeight: 1,
        fontWeight: 500
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontWeight: 600,
        borderBottom: '1px solid #e8e8e8',
        fontSize: 17,
        height: 40,
        color: '#15192c',
        backgroundColor: 'transparent',
        lineHeight: 1
    },
    body: {
        fontSize: 16,
        borderBottom: 'none',
        color: '#15192c',
        height: 40,
        backgroundColor: '#f8f9fc',
        lineHeight: 1,
        fontWeight: 500
    },
    root: {
        borderBottom: "none"
    },
}))(TableCell);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type

const ManageUsersScreen: React.FC = () => {
    const classes = useStyles();
    const {userService} = useServices();
    const fetchUsersInfo = async () => {
        const data = await userService.fetchUsers('1');
        return data;
    };

    const usersList = useAsync(async () => await fetchUsersInfo(), []);

    const [addUserPopup, setAddUserPopup] = useState(false);
    return (
        <>
            {addUserPopup && <AddUserPopup addUserPopup={addUserPopup} setAddUserPopup={setAddUserPopup}/>}
            {usersList.loading
                ? <LoaderScreen/>
                : usersList.error
                    ? (
                        <div>
                            Error:
                            {usersList.error.message}
                        </div>
                    )
                    : (
                        <TableContainer component={Paper} style={{padding: 20, minHeight: 600}}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <StyledTableCell align="left"
                                                         className={classes.leftBorderRadius}>User</StyledTableCell>
                                        <StyledTableCell align="left">Email</StyledTableCell>
                                        <StyledTableCell align="left">Role</StyledTableCell>
                                        <StyledTableCell align="left">Last Active</StyledTableCell>
                                        <StyledTableCell align="left">Device</StyledTableCell>
                                        <StyledTableCell align="left" className={classes.rightBorderRadius}/>
                                    </TableRow>
                                </TableHead>

                                <br/>

                                <TableBody style={{border: 'none',}}>
                                    {/*@ts-ignore*/}
                                    {usersList.value.users.map((user) => (
                                        <>
                                            <TableRow className={classes.tableRow}>
                                                <StyledTableCell align="left" className={classes.leftBorderRadius}>
                                                    <div className={classes.userInfoContainer}>
                                                        <Avatar src={UserImage} className={classes.userImage}/>
                                                        <Typography className={classes.userNameText}>
                                                            {user.name}
                                                        </Typography>
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    {user.email}
                                                </StyledTableCell>
                                                <StyledTableCell align="left">{user.role}</StyledTableCell>
                                                <StyledTableCell align="left">{user.lastActive}</StyledTableCell>
                                                <StyledTableCell align="left">
                                                    {user.device}
                                                </StyledTableCell>
                                                <StyledTableCell align="left" className={classes.rightBorderRadius}>
                                                    <MoreHorizIcon style={{color: 'grey'}}/>
                                                </StyledTableCell>
                                            </TableRow>
                                            <br/>
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}

            <Fab color="primary" style={{position:'fixed', right: 24, bottom : 24}} onClick={()=>setAddUserPopup(true)}>
                <AddIcon />
            </Fab>
        </>
    );
};

export default ManageUsersScreen;
