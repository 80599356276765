import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography } from '@material-ui/core';

import { notifyError, notifySuccess } from '../../components/Notification';
// import app from '../../config/firebaseCred';

const ForgetPasswordScreen = () => {
  const [disabled, setIsDisabled] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    // if (userNameTyped === user?.username) {
    //   setIsDisabled(false);
    // }
    // if (userNameTyped !== user?.username) {
    //   setIsDisabled(true);
    // }
  });

  const sendLink = async () => {
    try {
      // await app.auth().sendPasswordResetEmail(email);
      notifySuccess('A link to reset password is successfully sent');
    } catch (e) {
      notifyError('Some error occured');
    }
  };

  return (
    <div>
      <Typography style={{ fontSize: 30 }}>
        Enter email
      </Typography>
      <TextField
        id="standard-basic"
        label="Username"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={{ width: 300 }}
        inputProps={{ style: { fontSize: 20 } }}
        InputLabelProps={{ style: { fontSize: 20 } }}
      />
      <Button
        style={{ paddingTop: 12, paddingBottom: 12 }}
        onClick={() => sendLink()}
        disabled={disabled}
      >
        <Typography>Submit</Typography>
      </Button>

    </div>
  );
};

export default ForgetPasswordScreen;
