import React, { useState } from 'react';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import FilterIcon from '../../../assets/icons/filter.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 6,
  },
  selectedTab: {
    backgroundColor: 'white',
    borderBottom: '2px solid #551fff',
  },
  tab: {
    paddingTop: 12,
    paddingBottom: 12,
    cursor: 'pointer',
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabText: {
    color: '#15192c',
    lineHeight: 1,
    fontSize: 20,
    fontWeight: 'bold',
  },
  icon: {
    height: 36,
    width: 36,
    marginLeft: 12,
    marginRight: 12,
    cursor: 'pointer',
  },
}));

enum Tabs {
    ALL = 'All',
    CREATEDBYME = 'Created by Me',
    SHAREDWITHME='Shared with Me'
}

export const ReportTabs:React.FC = () => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(Tabs.ALL);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const tabComponent = (text: Tabs, isSelected: boolean) => (
    <Grid
      item
      xs={4}
      className={`${classes.tab} ${isSelected ? classes.selectedTab : ''}`}
      onClick={() => setSelectedTab(text)}
    >
      <Typography
        className={classes.tabText}
        align="center"
      >
        {text}
      </Typography>
    </Grid>
  );

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.root}
        xs={12}
      >
        <Grid container direction="row" xs={6}>
          {tabComponent(Tabs.ALL, selectedTab === Tabs.ALL)}
          {tabComponent(Tabs.CREATEDBYME, selectedTab === Tabs.CREATEDBYME)}
          {tabComponent(Tabs.SHAREDWITHME, selectedTab === Tabs.SHAREDWITHME)}
        </Grid>
        <Grid container direction="row" xs={6} justify="flex-end">
          <img src={FilterIcon} alt="Filter" className={classes.icon} />
        </Grid>
      </Grid>

    </div>
  );
};
