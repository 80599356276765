import { apiFailureErrorMessage, HttpService } from '../utils/httpService';

export class ThirdPartyServices {
  constructor(private httpService: HttpService) {
    this.httpService = httpService;
  }

  async fetchDataFromBranchLink(branchUrl: string) {
    const url = `https://api2.branch.io/v1/url?url=${branchUrl}/&branch_key=key_live_bdHuAw8zCFvRGEQZh6hDKgpmqApmQ2b3`;
    try {
      const { data } = await this.httpService.get<any>(url);
      return data;
    } catch (error) {
      throw new Error(apiFailureErrorMessage);
    }
  }
}
