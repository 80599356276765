import React from 'react';
import {
  Typography,
  Grid,
  makeStyles,
  CardActionArea,
  Avatar,
  Card,
  Chip,
} from '@material-ui/core';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { useHistory } from 'react-router-dom';
import Calendar from '../../../assets/icons/calendar.svg';
import Clock from '../../../assets/icons/clock.svg';
import TagIcon from '../../../assets/icons/tag.svg';

const useStyles = makeStyles(() => ({
  container: {
    marginRight: 20,
    paddingBottom: 24,
    borderRadius: 5,
    width: 300,
  },
  root: {
    width: 280,
    padding: 16,
    borderRadius: 20,
    backgroundColor: '#f8f9fc',
    height: 320,
    boxShadow: '4px 4px 5px 0 rgba(229, 229, 229, 0.63)',

  },
  image: {
    height: 130,
    width: 250,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 16,
  },
  playTimeTag: {
    zIndex: 10,
    position: 'absolute',
    bottom: 10,
    right: 10,
    width: 50,
    borderRadius: 5,
    backgroundColor: '#ff6a00',
    paddingTop: 2,
    paddingBottom: 2,
    color: 'white',
    fontSize: 12,
    display: 'flex',
    justifyContent: 'center',
  },
  nameText: {
    fontSize: 16,
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: '#15192c',
    lineHeight: 1.25,
  },
  dateTimeText: {
    fontSize: 15,
    color: '#52575c',
  },
  tags: {
    margin: 2,
    marginBottom: 0,
  },
  avatar: {
    height: 28,
    width: 28,
  },
}));

interface CardProps {video: any;setSelectedContent: any}

// eslint-disable-next-line react/prop-types
export const VideoCard: React.FC<CardProps> = ({ video, setSelectedContent }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.container}>
      <CardActionArea style={{ borderRadius: 20, width: 280 }}>
        <Card className={classes.root}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div style={{ position: 'relative' }} onClick={setSelectedContent}>
            <div
              className={classes.image}
            /* eslint-disable-next-line react/prop-types */
              style={{ backgroundImage: `url(${video.imageUrl})` }}
            />
            <div className={classes.playTimeTag}>
              {/* eslint-disable-next-line react/prop-types */}
              {video.playtime}
            </div>
          </div>

          <div style={{ paddingTop: 16 }}>
            {/* eslint-disable-next-line react/prop-types,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{ marginTop: 10, width: 250 }}
              /* eslint-disable-next-line react/prop-types */
              onClick={() => history.push(`/recordings/${video.id}`)}
            >

              <Grid item xs={12}>
                <Typography
                  className={classes.nameText}
                  align="left"
                >
                  {/* eslint-disable-next-line react/prop-types */}
                  {video.name}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{ marginTop: 10 }}
            >
              <Grid
                container
                direction="row"
                alignItems="center"
                xs={6}
              >
                <img src={Calendar} width={18} height={18} alt="Calendar" style={{ marginRight: 6 }} />
                <Typography className={classes.dateTimeText}>
                  {/* eslint-disable-next-line react/prop-types */}
                  {video.date}
                </Typography>
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="center"
                xs={6}
              >
                <img src={Clock} width={18} height={18} alt="Calendar" style={{ marginRight: 6 }} />
                <Typography className={classes.dateTimeText}>
                  {/* eslint-disable-next-line react/prop-types */}
                  {video.time}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              alignItems="center"
              style={{ marginTop: 20 }}
            >
              <img src={TagIcon} alt="Tag" height={18} width={18} style={{ marginRight: 8 }} />
              <Grid item wrap="wrap">
                {/* eslint-disable-next-line react/prop-types */}
                {video.tags.map((tag: React.ReactNode) => (
                  <Chip
                    variant="outlined"
                    size="small"
                    className={classes.tags}
                    label={tag}
                  />
                ))}

              </Grid>
            </Grid>
          </div>
        </Card>
      </CardActionArea>
    </div>
  );
};
