/* eslint-disable */
/* eslint-env browser */

import React, { useEffect } from "react";
import {Route, Switch, useHistory} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import LoginScreen from './screens/LoginScreen/LoginScreen';
import Signup from './screens/SignupScreen/Signup';
import ForgetPasswordScreen from './screens/ForgetPasswordScreen/ForgetPasswordScreen';
import RecordingScreen from './screens/RecordingScreen/RecordingScreen';
import StreamingScreen from './screens/StreamingScreen/StreamingScreen';
import ManageUsersScreen from './screens/ManageUsersScreen/ManageUsersScreen';
import ComingSoonScreen from './screens/ComingSoonScreen';
import VidePlayerScreen from './screens/VideoPlayerScreen/VideoPlayerScreen';
import { useAppConfigActions } from './context';
import LoaderScreen from "./screens/LoaderScreen/LoaderScreen";
import { useState } from "react";
import EventsScreen from "./screens/EventsScreen/EventsScreen";
import ReportScreen from "./screens/ReportsScreen/ReportScreen";
import {Dashboard} from "@material-ui/icons";
import DashboardScreen from "./screens/DashboardScreen/DashboardScreen";
import DevicesScreen from './screens/DevicesScreen/DevicesScreen';
import { Navigation } from "./components/Navigation/Navigation";

export const AppRouter = () => {
  const history = useHistory();
  const {setUserData} = useAppConfigActions()
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const sync = async () => {
      const email = await localStorage.getItem('email');
      if(!email){
        history.push('/login')
        setLoading(false);
      }
      else {
        setUserData({email: email})
        setLoading(false);

        return;
      }
    }
    sync();
  }, [])

  return (
    <>
      {
        loading ? <LoaderScreen/> : (
          <Switch>
            <Route exact path="/login" render={() => <LoginScreen/>}/>
            <Route exact path="/signup" component={Signup}/>
            <Route exact path="/forgot-password" component={ForgetPasswordScreen}/>
            <Navigation>
              {/*<PrivateRoute exact path="/overview" component={ ComingSoonScreen}/>*/}
              <PrivateRoute exact path="/" component={RecordingScreen}/>
              <PrivateRoute exact path="/recordings" component={RecordingScreen}/>
              <PrivateRoute exact path="/devices" component={StreamingScreen}/>
              <PrivateRoute exact path="/users" component={ManageUsersScreen}/>
              <Route exact path="/events" render={() => <EventsScreen/>}/>
              <Route exact path="/subscriptions" render={() => <ComingSoonScreen/>}/>
              <Route exact path="/help" render={() => <ComingSoonScreen/>}/>
              <Route exact path="/settings" render={() => <VidePlayerScreen/>}/>
              <Route exact path="/reports" render={() => <ReportScreen/>}/>
              <Route exact path="/dashboard" render={() => <DevicesScreen/>}/>
              <Route exact path="/devicesJanus" render={() => <DevicesScreen/>}/>
            </Navigation>
          </Switch>
        )
      }
    </>

  )


}
