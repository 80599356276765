/* eslint-disable */
/* eslint-env browser */

import firebase from 'firebase/app';

import('firebase/auth');

const config = {
  apiKey: "AIzaSyA9G6mxa-WRVzwpGMbMQKKewWpGhFIazKs",
  authDomain: "collablens.firebaseapp.com",
  projectId: "collablens",
  storageBucket: "collablens.appspot.com",
  messagingSenderId: "995621724909",
  appId: "1:995621724909:web:b241df0b07df19c5aee2e4",
  measurementId: "G-7W08B39TX1"
};

firebase.initializeApp(config);
export const firebaseAuth = firebase.auth();
