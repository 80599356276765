/* eslint-disable */
/* eslint-env browser */
import React, { useEffect, useState } from 'react';
import {
  AppBar,
  CssBaseline, Drawer, Hidden, List, ListItem, ListItemIcon, ListItemText,
  makeStyles, Toolbar, Typography,
  useTheme,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import { useHistory } from 'react-router-dom';
import NavigationDivider from './NavigationDivider';
import Logo from '../../assets/icons/logo/Menmitsu Logo_Primary.png';
import CalendarIcon from '../../assets/icons/navigationIcons/calendar.svg';
import CameraIcon from '../../assets/icons/navigationIcons/camera.svg';
import CopyIcon from '../../assets/icons/navigationIcons/copy.svg';
import TriangleIcon from '../../assets/icons/navigationIcons/triangle.svg';
import VideoIcon from '../../assets/icons/navigationIcons/video.svg';
import DateCalendarIcon from '../../assets/icons/iconly-light-calendar.svg';
import moment from 'moment';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: '#f8f9fc',
    boxShadow: 'none',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  toolbarTitleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  drawerPaper: {
    width: drawerWidth,
    overflowX: 'hidden',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  selectedListItem: {
    backgroundColor: 'rgba(85, 31, 255, 0.08)',
  },
  listItem: {
    height: 60,
    paddingBottom: 20,
    paddingTop: 20,
    fontSize: 18,
    marginTop: 8,
    marginBottom: 8,
    '&:hover': {
      backgroundColor: 'rgba(85, 31, 255, 0.08)',
    },
  },
  selectedListItemText: {
    fontSize: 22,
    fontWeight: 'normal',
  },
  unselectedListItemText: {
    fontSize: 16,
    fontWeight: 300,
  },
  brandLogoContainer: {
    display: 'flex',
    width: 60,
    justifyContent: 'center',
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: window.innerHeight - 72,
  },
  subListContainer: {
    width: '100%',
  },
}));

enum NavigationOptions {
  'Overview' = 'Overview',
  'Recordings' = 'Recordings',
  'Events' = 'Events',
  'Users' = 'Users',
  'Devices' = 'Devices',
  'Subscriptions' = 'Subscriptions',
  'Settings' = 'Settings',
  'Help' = 'Help',
  'Logout' = 'Logout',
  'Streaming' = 'Streaming'
}

interface listItemProps{
  navigationOptions:string;
  isSelected:boolean;
}

// eslint-disable-next-line react/prop-types
export const Navigation: React.FC = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = useState(NavigationOptions.Streaming);

  useEffect(() => {
    switch (selectedCategory) {
      case NavigationOptions.Overview:
        history.push('/dashboard');
        break;
      case NavigationOptions.Devices:
        history.push('/devices');
        break;
      case NavigationOptions.Recordings:
        history.push('/recordings');
        break;
      case NavigationOptions.Users:
        history.push('/users');
        break;
      case NavigationOptions.Help:
        history.push('/help');
        break;
      case NavigationOptions.Events:
        history.push('/events');
        break;
      case NavigationOptions.Streaming:
        history.push('/devicesJanus');
        break;
      case NavigationOptions.Settings:
        history.push('/settings');
        break;
      case NavigationOptions.Logout:
        history.push('/signup');
        break;

      default:
        break;
    }
  }, [selectedCategory]);

  const listItem:(navigationOption: NavigationOptions, isSelected: boolean, icon?:any) => JSX.Element = (navigationOption: NavigationOptions, isSelected: boolean, icon? : any) => (
    <ListItem
      button
      key={navigationOption}
      onClick={() => setSelectedCategory(navigationOption)}
      className={`${classes.listItem} ${isSelected ? classes.selectedListItem : ''}`}
    >
      <ListItemIcon>
        {icon ? <img src={icon} alt="Navigation" /> : <MailIcon />}
      </ListItemIcon>
      <Typography className={isSelected ? classes.selectedListItemText : classes.unselectedListItemText}>
        {navigationOption}
      </Typography>
    </ListItem>
  );

  const drawer = (
    <div>
      <div className={`${classes.toolbar} ${classes.toolbarTitleContainer}`}>
        <div className={classes.brandLogoContainer}>
          <img src={Logo} alt="CollabLens" height={40} width={40} />
        </div>
        <div style={{ flex: 7 }} />
      </div>
      <NavigationDivider />
      <div className={classes.listContainer}>
        <div className={classes.subListContainer}>
          <List>
            {listItem(NavigationOptions.Overview, selectedCategory === NavigationOptions.Overview, CopyIcon)}
            {listItem(NavigationOptions.Recordings, selectedCategory === NavigationOptions.Recordings, VideoIcon)}
            {listItem(NavigationOptions.Events, selectedCategory === NavigationOptions.Events, CalendarIcon)}
            {listItem(NavigationOptions.Devices, selectedCategory === NavigationOptions.Devices, CameraIcon)}
            {listItem(NavigationOptions.Users, selectedCategory === NavigationOptions.Users, TriangleIcon)}
            {listItem(NavigationOptions.Streaming, selectedCategory === NavigationOptions.Streaming)}
          </List>
        </div>
        {/*<div className={classes.subListContainer}>*/}
        {/*  {listItem(NavigationOptions.Settings, selectedCategory === NavigationOptions.Settings)}*/}
        {/*  {listItem(NavigationOptions.Help, selectedCategory === NavigationOptions.Help)}*/}
        {/*  <NavigationDivider />*/}
        {/*  {listItem(NavigationOptions.Logout, selectedCategory === NavigationOptions.Logout)}*/}
        {/*  <List />*/}
        {/*</div>*/}

      </div>
    </div>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  const handleDrawerToggle: () => void = () => {
    setMobileOpen(!mobileOpen);
  };
  const d = new Date();
  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar style={{
            width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
          }}
          >
            <div style={{
              display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: 'white', padding: 12, borderRadius: 12, marginRight: 20,
            }}

            >
              <img src={DateCalendarIcon} height={24} width={24} style={{ marginRight: 12 }} alt="" />
              <Typography style={{
                fontSize: 18, fontWeight: 500, color: '#15192c',
              }}
              >
                {moment().format('MMMM Do YYYY')}
              </Typography>

            </div>
            <AccountCircleIcon color="primary" />
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
    </>
  );
};
