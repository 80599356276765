/* eslint-disable */
/* eslint-env browser */

import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import RecordingScreen from './screens/RecordingScreen/RecordingScreen';
import DevicesScreen from './screens/DevicesScreen/DevicesScreen';
import ManageUsersScreen from './screens/ManageUsersScreen/ManageUsersScreen';
import { ServicesProvider } from './services';
import { AuthProvider } from './context/AuthContext';
import LoginScreen from './screens/LoginScreen/LoginScreen';
import SignUp from './screens/SignupScreen/Signup';
import ForgetPasswordScreen from './screens/ForgetPasswordScreen/ForgetPasswordScreen';
import ComingSoonScreen from './screens/ComingSoonScreen';
import VidePlayerScreen from './screens/VideoPlayerScreen/VideoPlayerScreen';
import StreamingScreen from './screens/StreamingScreen/StreamingScreen.jsx';
import Signup from './screens/SignupScreen/Signup';
import { AppConfigDataContext } from './context';
import PrivateRoute from './PrivateRoute';
import { AppRouter } from './AppRouter';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type

function App() {
  return (
    <div>
      <ServicesProvider>
        <AppConfigDataContext>
          <AuthProvider>
            <Router>
              <AppRouter/>
            </Router>
          </AuthProvider>
        </AppConfigDataContext>
      </ServicesProvider>
    </div>
  );
}

export default App;
